import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1080.000000 978.000000">
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,978.000000) scale(0.100000,-0.100000)">


<path d="M0 4890 l0 -4890 5400 0 5400 0 0 4890 0 4890 -5400 0 -5400 0 0
-4890z m3965 2287 c69 -27 83 -46 109 -152 21 -87 30 -121 55 -210 11 -38 41
-149 65 -245 44 -169 63 -244 139 -530 20 -74 50 -187 66 -250 17 -63 58 -218
91 -345 33 -126 74 -285 91 -351 l31 -122 -25 -43 c-13 -24 -75 -132 -137
-239 -62 -107 -122 -211 -132 -230 -11 -19 -24 -39 -28 -45 -4 -5 -21 -35 -38
-65 -16 -30 -53 -95 -82 -145 -29 -49 -86 -148 -128 -220 -41 -71 -82 -143
-92 -160 -9 -16 -58 -101 -108 -188 -51 -88 -92 -163 -92 -168 0 -5 -4 -9 -9
-9 -5 0 -13 -9 -16 -21 -4 -11 -9 -18 -12 -15 -6 6 5 36 43 121 12 28 42 97
67 155 25 58 61 143 81 190 21 47 46 105 56 130 10 25 41 97 68 160 38 86 49
123 45 148 -5 28 -9 32 -37 32 -268 2 -1041 -1 -1043 -4 -2 -2 -41 -149 -86
-328 -45 -178 -89 -335 -97 -347 -8 -12 -32 -26 -55 -31 -44 -11 -574 -14
-600 -4 -19 8 -48 61 -40 74 3 5 10 30 16 57 14 69 22 99 68 273 23 85 59 223
80 305 22 83 49 186 61 230 69 259 92 348 186 710 24 94 48 186 54 205 6 19
49 181 96 360 47 179 96 366 109 415 13 50 35 134 49 188 14 53 38 141 52 195
14 53 30 113 34 132 5 19 14 53 20 75 6 22 15 56 20 75 40 167 54 194 120 228
44 22 48 22 450 21 284 0 414 -4 435 -12z m1966 -239 c-129 -298 -211 -494
-211 -505 0 -8 45 -12 136 -12 312 -3 520 -79 684 -252 74 -78 119 -147 160
-248 44 -108 61 -201 67 -373 7 -187 0 -211 -61 -239 -36 -16 -69 -19 -289
-19 -342 0 -349 4 -355 189 -5 150 -33 226 -99 271 -70 48 -96 52 -334 57
-206 5 -303 -1 -376 -23 -13 -3 -23 -2 -23 4 0 5 48 92 106 193 59 101 118
204 132 229 58 103 175 306 182 315 4 6 24 42 45 80 21 39 43 77 50 85 7 8 25
40 42 70 35 66 59 108 124 218 27 45 49 87 49 92 0 6 3 10 8 10 4 0 14 14 21
32 8 18 16 27 18 21 2 -6 -32 -94 -76 -195z m1940 -591 c-6 -6 -11 -19 -11
-29 0 -10 -4 -18 -10 -18 -5 0 -10 -7 -10 -15 0 -8 -13 -44 -30 -80 -16 -36
-30 -68 -30 -70 0 -3 -16 -40 -36 -82 -20 -43 -43 -96 -51 -118 -8 -22 -24
-60 -36 -85 -25 -56 -77 -182 -77 -186 0 -2 64 -4 143 -4 343 0 569 -79 738
-256 69 -73 117 -148 154 -239 71 -175 75 -242 75 -1280 0 -1073 -4 -1125 -94
-1321 -109 -235 -316 -382 -616 -436 -112 -20 -1595 -19 -1633 1 -53 28 -52
20 -52 801 l0 725 43 69 c23 37 42 70 42 72 0 2 14 27 32 56 51 87 318 549
351 608 16 30 38 66 47 80 9 14 31 50 47 80 84 152 125 220 134 220 5 0 9
-397 9 -1040 0 -1027 0 -1040 20 -1060 19 -19 33 -20 342 -20 413 0 486 12
552 91 65 77 61 10 64 1039 4 1063 5 1044 -74 1123 -67 67 -132 77 -489 77
-162 0 -296 3 -298 8 -3 4 11 34 31 67 19 33 52 89 72 125 20 36 106 184 190
330 84 146 164 285 178 310 14 25 45 79 70 120 25 41 59 100 75 130 17 30 48
84 69 119 21 34 38 67 38 71 0 4 7 10 15 14 8 3 15 13 16 23 0 12 2 14 6 5 3
-8 0 -19 -6 -25z m-2786 -965 c0 -135 2 -151 23 -191 59 -112 180 -166 427
-191 279 -29 493 -72 697 -140 128 -43 179 -66 243 -110 51 -36 141 -124 172
-172 l25 -37 -31 -51 c-17 -28 -31 -53 -31 -56 0 -3 -8 -18 -19 -32 -10 -15
-32 -52 -48 -82 -16 -30 -41 -74 -56 -97 -15 -24 -27 -44 -27 -47 0 -2 -18
-34 -40 -70 -22 -36 -40 -70 -40 -76 0 -5 -4 -10 -10 -10 -5 0 -10 -4 -10 -10
0 -5 -27 -55 -60 -110 -33 -55 -60 -105 -60 -110 0 -6 -4 -10 -9 -10 -5 0 -11
-8 -14 -17 -6 -19 -51 -100 -107 -193 -18 -30 -74 -127 -125 -215 -302 -525
-373 -648 -469 -813 -58 -101 -106 -186 -106 -188 0 -3 -11 -21 -24 -42 -12
-20 -38 -61 -56 -90 -38 -62 -50 -63 -21 -1 12 23 21 47 21 52 0 4 13 36 29
70 54 115 221 509 221 523 0 11 -35 14 -192 14 -112 0 -231 6 -283 14 -364 56
-611 277 -691 621 -19 79 -30 401 -16 437 22 55 33 57 334 58 301 2 322 -2
346 -55 7 -14 12 -59 12 -99 0 -160 41 -268 118 -313 72 -42 145 -52 389 -53
299 0 374 17 445 100 43 49 55 88 70 224 23 214 -28 328 -179 405 -61 30 -174
58 -293 71 -328 36 -653 113 -841 198 -74 34 -219 153 -219 179 0 5 28 55 61
113 34 58 75 130 92 160 16 30 38 67 49 82 10 14 18 29 18 32 0 3 14 29 32 58
107 180 238 412 238 421 0 6 3 7 8 3 4 -4 7 -74 7 -154z"/>
<path d="M3516 6498 c-2 -7 -25 -98 -50 -203 -26 -104 -51 -206 -56 -225 -17
-62 -122 -484 -131 -525 -4 -22 -40 -166 -78 -319 -39 -154 -68 -283 -65 -288
3 -4 183 -8 400 -8 305 0 394 3 394 12 0 11 -290 1187 -364 1472 -21 84 -40
116 -50 84z"/>
</g>
</svg>


		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
